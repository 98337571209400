<template>
  <div id="advanced-report" v-if="checkPermission('list', 'action', 'advance-reports')">
    <topic-component :topic="$t('SideBarItems.reports.list.advancedReport')"></topic-component>
    <div class="grid-advanced-report-card">
      <div class="grid-advanced-report-card__advanced-card"
           :style="backgroundImage(card.img)"
           v-for="card in advanced_report_cards" :key="card.id">
        <p class="advanced-card__title">{{ $t(card.title) }}</p>
        <p class="advanced-card__total">{{ displayTotal(card.key) }}</p>
      </div>
    </div>
    <div class="flex-status-describe">
      <div v-for="status in advanced_report_cards.slice(0,2)" :key="status.id"
           class="flex-status-describe__content-mb">
        <div class="content-title flex-center" :class="{'custom-content-title':checkLocale}">
          <p :class="`${colorStatus(status.background_color)}`">{{ $t(status.title) }}</p>
        </div>
        <div class="content-description "> {{ $t(status.description) }}</div>
      </div>
      <b-collapse v-model="visible" class="flex-status-describe__expand">
        <div v-for="status in advanced_report_cards.slice(2)" :key="status.id"
             class="flex-status-describe__content-mb">
          <div class="content-title flex-center" :class="{'custom-content-title':checkLocale}">
            <p :class="`${colorStatus(status.background_color)}`">{{ $t(status.title) }}</p>
          </div>
          <div class="content-description "> {{ $t(status.description) }}</div>
        </div>
      </b-collapse>
      <button @click="visible = !visible" class="flex-status-describe__toggle btn btn-outline">
        <p>{{ $t('button.showMore') }}</p>
        <span style="width: 18px; height: 16px" :style="iconMaskURL('arrow-caret')" class="caret"
              :class="{ 'caret-rotate': visible}"></span>
      </button>
      <div v-for="status in advanced_report_cards" :key="status.id" class="flex-status-describe__content">
        <div class="content-title flex-center" :class="{'custom-content-title':checkLocale}">
          <p :class="`${colorStatus(status.background_color)}`">{{ $t(status.title) }}</p>
        </div>
        <div class="content-description "> {{ $t(status.description) }}</div>
      </div>
    </div>
    <b-card class="mt-3" id="advanced-report-list">
      <div class="advanced-report-list__filter">
        <div class="filter-month-year">
          <b-form-group label-cols="12" :label="$t('advancedReport.table.sender')" class="custom-form-group--filter">
            <b-form-input v-model="form.sender_name"/>
          </b-form-group>
          <select-filter-component
              class="custom-form-group--filter"
              :options="filter_month"
              :block_first_template="filter_month.length > 0 "
              v-model="form_filter.select_filter_month"
              :label-select="$t('common.month')"
          />

          <select-filter-component
              class="custom-form-group--filter"
              :options="filter_year"
              :block_first_template="filter_month.length > 0 "
              v-model="form_filter.select_filter_year"
              :label-select="$t('common.year')"
          />
        </div>
        <div class="re-size-margin-button">
          <button type="button" class="btn waves-effect waves-light btn-gradient"
                  @click="filterSearch()">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline"
                  @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </div>
      </div>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{ $t('common.perPage') }}</span>
            <div>
              <b-form-select
                  v-model="form.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="getAdvancedReport"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
        <b-col align="end">
          <b-button variant="primary" class="mr-1" @click="exportAdvancedReport"
                    v-if="checkPermission('export', 'action', 'advance-reports')">
            <i class="fas fa-file-excel "></i>
            {{ $t("button.export") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table
            responsive
            class="mb-0"
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(createdAt)="data">
            <p>{{ format(new Date(data.item.transaction_date), 'dd/MM/yyyy') }}</p>
          </template>
          <template #cell(sender)="data">
            <p>{{ data.item.sender_name }}</p>
          </template>
          <template #cell(senderType)="data">
            <p>{{ data.item.sender_type }}</p>
          </template>
          <template #cell(amountSending)="data">
            <p class="text-blue text-bold">{{ thousandSeparator(data.item.total_sent) }}</p>
          </template>
          <template #cell(amountReceived)="data">
            <p class="text-success text-bold">{{ thousandSeparator(data.item.total_success) }}</p>
          </template>
          <template #cell(amountFailed)="data">
            <p>{{ thousandSeparator(data.item.total_failed) }}</p>
          </template>
          <template #cell(amountNotReceived_A)="data">
            <p>{{ thousandSeparator(data.item.total_block_spam) }}</p>
          </template>
          <template #cell(amountNotReceived_M)="data">
            <p>{{ thousandSeparator(data.item.total_destination_error) }}</p>
          </template>
          <template #cell(backList)="data">
            <p>{{ thousandSeparator(data.item.total_blacklist) }}</p>
          </template>
        </b-table>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="total_rows"
              :per-page="form.$limit"
              align="fill"
              class="adjust-pagination"
              size="sm"
              @input="getAdvancedReport"
          ></b-pagination>
        </div>
      </b-row>
    </b-card>
    <b-modal
        id="modal-export-advanced-report"
        ok-variant="info"
        hide-footer
        no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5></h5>
        <i class="fas fa-times hover-icon action-button" @click="close"></i>
      </template>
      <div align="center" v-if="!exportSuccess">
        <b-spinner
            class="mt-5 mb-1"
            style="width: 4rem; height: 4rem"
            label="Large Spinner"
            variant="warning"
            type="grow">
        </b-spinner>
        <h3 v-html="$t('processing')" class="mb-3"></h3>
      </div>
      <div v-if="exportSuccess">
        <div align="center" v-if="exportSuccess&&step_success">
          <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
          <h3 v-html="$t('success')" class="mb-3"></h3>
          <div v-for="items in fileExport">
            <a :href="items">{{ items }}</a>
            <hr/>
          </div>
        </div>
        <div align="center" v-if="!exportSuccess&&step_success">
          <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2"/>
          <h3 v-html="$t('failed')" class="mb-3"></h3>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import TopicComponent from "@/components/common/topicComponent.vue";
import functionsCommon from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import moment from "moment/moment";
import reportApi from "@/repository/reportApi";
import SelectFilterComponent from "@/components/common/selectFilterComponent.vue";
import masterData from "@/common/masterData.json";
import {format} from "date-fns";
import alert from "@/common/alert";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  components: {
    TotalListComponent,
    SelectFilterComponent,
    EmptyTable,
    TopicComponent
  },
  data() {
    return {
      totalAll: 0,
      exportSuccess: false,
      step_success: false,
      fileExport: [],
      advanced_report_cards: [
        {
          key: 'total_sent',
          background_color: 'blue',
          img: 'total-sending.png',
          title: 'advancedReport.advancedReportCard.totalNumberSent',
          description: 'advancedReport.advancedReportStatus.descriptionTotalNumberSent',
        },
        {
          key: 'total_success',
          background_color: 'green',
          img: 'total-received.svg',
          title: 'advancedReport.advancedReportCard.amountReceived',
          description: 'advancedReport.advancedReportStatus.descriptionAmountReceived',
        },
        {
          key: 'total_failed',
          background_color: 'red',
          img: 'total-fail.svg',
          title: 'advancedReport.advancedReportCard.amountFailed',
          description: 'advancedReport.advancedReportStatus.descriptionAmountFailed',
        },
        {
          key: 'total_block_spam',
          background_color: 'red',
          img: 'total-fail.svg',
          title: 'advancedReport.advancedReportCard.amountNotReceivedA',
          description: 'advancedReport.advancedReportStatus.descriptionAmountNotReceivedA'
        },
        {
          key: 'total_destination_error',
          background_color: 'red',
          img: 'total-fail.svg',
          title: 'advancedReport.advancedReportCard.amountNotReceivedM',
          description: 'advancedReport.advancedReportStatus.descriptionAmountNotReceivedM'
        },
        {
          key: 'total_blacklist',
          background_color: 'black',
          img: 'blacklist.svg',
          title: 'advancedReport.advancedReportCard.blackList',
          description: 'advancedReport.advancedReportStatus.descriptionBlackList'
        }
      ],
      form: {
        sender_name: null,
        date_from: null,
        date_to: null,
        $skip: 1,
        $limit: 10,
      },
      form_filter: {
        select_filter_month: null,
        select_filter_year: null
      },
      summary: null,
      filter_month_var: [],
      filter_year_var: [],
      visible: false,
      isBusy: false,
      fields: [
        {
          key: "createdAt",
          label: "advancedReport.table.date",
          class: 'text-left'
        },
        {
          key: "sender",
          label: "advancedReport.table.sender",
          class: 'text-left'
        },
        {
          key: "senderType",
          label: "advancedReport.table.senderType",
          class: 'text-left'
        },
        {
          key: "amountSending",
          label: "advancedReport.table.totalNumberSent",
          class: 'text-left'
        },
        {
          key: "amountReceived",
          label: "advancedReport.table.amountReceived",
          class: 'text-left'
        },
        {
          key: "amountFailed",
          label: "advancedReport.table.amountFailed",
          class: 'text-left'
        },
        {
          key: "amountNotReceived_A",
          label: "advancedReport.table.amountNotReceivedA",
          class: 'text-left'
        },
        {
          key: "amountNotReceived_M",
          label: "advancedReport.table.amountNotReceivedM",
          class: 'text-left'
        },
        {
          key: "backList",
          label: "advancedReport.table.blackList",
          class: 'text-left'
        }
      ],
      items: [],
      total_rows: 1,
      pageOptions: [10, 15, 50, 100],
    }
  },
  computed: {
    checkLocale() {
      return this.$i18n.locale === "th" || this.$i18n.locale === "en"
    },
    filter_month() {
      let month_number = new Date().getMonth()
      this.form_filter.select_filter_month = masterData.monthList[month_number].value
      this.filter_month_var = masterData.monthList
      return this.filter_month_var
    },
    filter_year() {
      const past = new Date().getFullYear() - 1
      const current = new Date().getFullYear()
      this.filter_year_var.push(
          {
            value: past,
            en: past,
            th: past,
            cn: past,
            id: past,
            my: past,
            vn: past,
          },
          {
            value: current,
            en: current,
            th: current,
            cn: current,
            id: current,
            my: current,
            vn: current,
          }
      )
      this.form_filter.select_filter_year = this.filter_year_var[1].value
      return this.filter_year_var
    },
  },
  methods: {
    format,
    thousandSeparator(word) {
      return word.toLocaleString()
    },
    checkPermission(permission, type, modules) {
      return functionsCommon.userCheckPermission(permission, type, modules)
    },
    displayTotal(key) {
      const all_status = {
        "total_sent": this.summary?.total_sent,
        "total_success": this.summary?.total_success,
        "total_failed": this.summary?.total_failed,
        "total_destination_error": this.summary?.total_destination_error,
        "total_block_spam": this.summary?.total_block_spam,
        "total_blacklist": this.summary?.total_blacklist
      }
      return all_status[key] ? all_status[key]?.toLocaleString() : 0
    },
    exportAdvancedReport() {
      this.$bvModal.show('modal-export-advanced-report')
      this.form.date_from = moment(new Date(this.form_filter.select_filter_year, this.form_filter.select_filter_month, 1)).format('YYYY-MM-DD') + 'T00:00:00'
      this.form.date_to = moment(new Date(this.form_filter.select_filter_year, this.form_filter.select_filter_month + 1, 0)).format('YYYY-MM-DD') + 'T23:59:59'
      const form = {
        file_type: "csv",
        date_from: this.form.date_from,
        date_to: this.form.date_to,
        sender_name: this.form.sender_name
      }
      reportApi.exportAdvancedReport(form).then(response => {
        if (response?.codeSYS === '001') {
          this.fileExport = response?.url_files
          this.exportSuccess = true
          this.step_success = true
        } else {
          this.exportSuccess = false
          this.step_success = false
        }
      }).catch(err => {
        this.$bvModal.hide('modal-export-advanced-report')
        alert.findFailed(this, err.response.data.message)
      })
    },
    getAdvancedReport() {
      this.isBusy = true
      this.form.date_from = moment(new Date(this.form_filter.select_filter_year, this.form_filter.select_filter_month, 1)).format('YYYY-MM-DD') + 'T00:00:00'
      this.form.date_to = moment(new Date(this.form_filter.select_filter_year, this.form_filter.select_filter_month + 1, 0)).format('YYYY-MM-DD') + 'T23:59:59'
      reportApi.getAdvancedReport(_.cloneDeep(this.form)).then(resp => {
        if (resp) {
          this.summary = resp?.summary
          this.items = resp?.data
          this.total_rows = resp?.total
          this.totalAll = resp?.total
        }
      }).finally(() => {
        this.isBusy = false
      })
    },
    iconMaskURL(name) {
      return functionsCommon.iconMaskURL(name)
    },
    colorStatus(bg) {
      const background = {
        blue: 'bg-status-blue',
        green: 'bg-status-green',
        red: 'bg-status-red',
        black: 'bg-status-black',
        gold: 'bg-status-gold',
      }
      return background[bg]
    },
    backgroundImage(img) {
      return functionsCommon.backgroundImage(img)
    },
    resetSearch() {
      this.form.$skip = 1
      this.form.$limit = 10
      this.form.sender_name = null
      const date = new Date()
      const month_number = new Date().getMonth()
      this.form_filter.select_filter_month = masterData.monthList[month_number].value
      this.form_filter.select_filter_year = new Date().getFullYear()
      this.form.date_from = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DD') + 'T00:00:00'
      this.form.date_to = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY-MM-DD') + 'T23:59:59'
      this.getAdvancedReport()
    },
    filterSearch() {
      this.form.$skip = 1
      this.getAdvancedReport()
    }
  },
  mounted() {
    this.getAdvancedReport()
  }
}
</script>
<style lang="scss">
#advanced-report {
  p, span {
    margin: 0px;
    padding: 0px;
  }

  .text-purple {
    color: #5C4CAE;
  }

  .advanced-report-list__filter {
    display: flex;
    align-items: flex-end;
    gap: 12px;
    width: 100%;
    margin-bottom: 24px;

    .custom-form-group--filter {
      width: 100%;
      margin: 0px !important;
      padding: 0px !important;
    }

    .filter-month-year {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 70%;
    }

    .re-size-margin-button {
      display: flex;
      gap: 8px;
      margin: 0;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;

      .re-size-margin-button {
        margin-top: 12px;
        gap: 4px;
        width: 100%;

        button {
          width: 100% !important;
        }
      }

      .filter-month-year {
        width: 100%;
        gap: 6px;
      }
    }

    @media (max-width: 576px) {
      .filter-month-year {
        flex-direction: column;
      }
    }
  }


  .caret {
    transform: rotate(0deg);
    transition-property: transform;
    transition-duration: 0.3s;
  }

  .caret-rotate {
    transform: rotate(180deg);
    transition-property: transform;
    transition-duration: 0.3s;
  }

  .grid-advanced-report-card {
    margin-top: 16px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    overflow: scroll;

    &::-webkit-scrollbar {
      display: none !important;
    }

    .grid-advanced-report-card__advanced-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      background-color: white;
      border-radius: 10px;
      padding: 12px;

      .advanced-card__title {
        font-size: 16px;
        font-weight: 600;
      }

      .advanced-card__total {
        font-size: 32px;
        font-weight: 600;
      }

      .advanced-card__title, .advanced-card__total {
        color: white;
      }
    }

    @media (max-width: 1800px) {
      gap: 20px;

      .grid-advanced-report-card__advanced-card {
        width: 380px;
      }
    }

    @media (max-width: 1600px) {
      gap: 20px;

      .grid-advanced-report-card__advanced-card {
        width: 320px;
      }
    }

    @media (max-width: 992px) {
      gap: 20px;

      .grid-advanced-report-card__advanced-card {
        width: 255px;
      }
    }

    @media (max-width: 768px) {
      gap: 20px;

      .grid-advanced-report-card__advanced-card {
        width: 213px;

        .advanced-card__total {
          font-size: 24px;
        }
      }
    }
  }


  .flex-status-describe {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 22px;
    padding: 20px;
    background: #FFF;
    border-radius: 10px;

    .flex-status-describe__content, .flex-status-describe__content-mb {
      display: flex;
      flex-direction: row;
      gap: 42px;

      div.content-description {
        color: #9F9F9F;
        font-size: 14px;
        font-weight: 600;
      }

      div.content-title, .custom-content-title {
        width: 220px;

        p {
          white-space: nowrap;
          width: auto;
          padding: 4px 12px;
          border-radius: 20px;
          font-size: 12px;
          font-weight: 500;
          color: #2F2E2E;
        }
      }

      .custom-content-title {
        width: 180px !important;
      }

      @media (max-width: 768px) {
        gap: 5px;
        div.content-description {
          width: 100%;
          justify-content: flex-start;
          display: flex;
        }

        div.content-title, .custom-content-title {
          width: 275px !important;
        }
      }

      @media (max-width: 576px) {
        display: none;
        flex-direction: column;
        gap: 12px;
      }

      .bg-status-blue {
        border: 1px solid #208EF1;
        background: #E7F3FF;
      }

      .bg-status-green {
        border: 1px solid var(--success);
        background: #E7F6EC;

      }

      .bg-status-red {
        border: 1px solid var(--danger);
        background: #FFE9E9;
      }

      .bg-status-black {
        border: 1px solid #525252;
        background: #DBDBDB;
      }

      .bg-status-gold {
        border: 1px solid #FFC700;
        background: #FFF6E8;
      }
    }

    .flex-status-describe__expand {
      display: none;
      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        gap: 22px;
      }
    }

    .flex-status-describe__toggle {
      display: none;
      @media (max-width: 576px) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
      }
    }

    .flex-status-describe__content-mb {
      display: none;
      @media (max-width: 576px) {
        display: flex;
      }
    }
  }

  #advanced-report-list {
    .adjust-pagination {
      display: flex;
      gap: 8px;
      align-items: center;
      margin: 8px 16px 0 0;
    }
  }
}
</style>